@import "src/tokens";

.root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: $bg-primary;
}

.badge {
  width: 100vw;
  height: 32px;
  background: url("https://talentindonesia.s3.ap-southeast-1.amazonaws.com/assets/jp/pattern.png");
  background-size: 256px 256px;
  box-shadow: inset 0px -12px 32px rgba(0, 0, 0, 0.2);
}

.main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  min-height: calc(100vh - 32px);
  max-width: 600px !important;
  padding-top: $space-ll;
  padding-bottom: $space-ll;
}

.header {
  padding-bottom: $space-ll;
  text-align: center;
  width: 100%;
}

.logoWrapper {
  display: block;
  margin-bottom: $space-ml;
  text-align: center;
}

.logo {
  height: 64px;
  margin-left: auto;
  margin-right: auto;
}

.title {
  padding-bottom: $space-sm;
}

.loginForm {
  width: 100%;
  display: block;
}

.footer {
  width: 100%;
  padding-top: $space-ll;
  border-top: 2px solid $dark-alpha-20;
  text-align: center;
}

.forgot {
  font-weight: $semibold;
  font-size: 15px;
  line-height: 16px;
  color: $brand-primary-200;
}

.cta {
  margin-top: $space-l;
}

.alternative {
  padding-top: $space-ml;
}

.limit {
  padding: $space-m;
  border-radius: $radius-m;
  background-color: $tone-danger-10;
  width: 100%;
  margin-bottom: $space-ml;
  margin-top: $space-ml;
}

.limitText {
  font-size: 14px;
  line-height: 16px;
  font-weight: 400;
  color: $tone-danger-300;
}
